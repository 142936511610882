import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Button, Table, Space } from 'antd';
import { DeleteOutlined, ToolOutlined } from '@ant-design/icons';

export function List({ data, onDelete, onEdit }) {
	const columns = [
		{
			title: I18n.get('Code'),
			dataIndex: 'id',
			key: 'id',
			align: 'center',
			render: (text) => <span>{text.substr(0, 7)}</span>
		},
		{
			title: I18n.get('Name'),
			dataIndex: 'name',
			key: 'name'
		},
		{
			title: I18n.get('E-mail'),
			dataIndex: 'email',
			key: 'email'
		},
		{
			title: I18n.get('Role'),
			dataIndex: 'role',
			key: 'role',
			align: 'center'
		},
		{
			title: I18n.get('Activity'),
			dataIndex: 'isActive',
			key: 'isActive',
			align: 'center',
			render: (text) => <span>{I18n.get(text ? 'Active' : 'Disabled')}</span>
		},
		{
			title: I18n.get('Actions'),
			key: 'action',
			align: 'center',
			render: (text, user) => (
				<Space size="middle">
					<Button type="primary" ghost onClick={() => onEdit(user)} icon={<ToolOutlined />} />
					<Button type="danger" ghost onClick={() => onDelete(user)} icon={<DeleteOutlined />} />
				</Space>
			)
		}
	];

	return (
		<Col sm={24} md={24}>
			<Table rowKey="id" pagination={{ pageSize: 6 }} columns={columns} dataSource={data} />
		</Col>
	);
}
