import { useQuery } from '@tanstack/react-query';
import type { EvaluatorDTO } from '@/components/ui/Inputs/types/evaluator';
import { QUERY_KEYS } from '@/utils/query-keys';
import type { Evaluator } from '@/types';
import Api from '@/services/api';

const { GET_EVALUATORS } = QUERY_KEYS;

interface GetEvaluators {
	organization_id: string;
	company_id: string;
}

const getEvaluators = async (params: EvaluatorDTO): Promise<Evaluator[]> => {
	const url = `/evaluator`;
	const { data } = await Api.get<Evaluator[]>(url, { params });
	return data;
};

export const useEvaluators = (params: GetEvaluators) => {
	return useQuery([GET_EVALUATORS, params], () => getEvaluators(params), {
		enabled: !!params.organization_id && !!params.company_id
	});
};
